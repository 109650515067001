<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>සැකසුම්</h4>
    </header>

    <v-sheet class="custom-shadow px-3">
      <v-row>
        <v-col class="d-flex grow align-center">මුරපදය වෙනස් කරන්න</v-col>
        <v-col class="shrink">
          <v-btn @click="passwordForm = !passwordForm" color="accent" depressed>{{editButtonText}}</v-btn>
        </v-col>
      </v-row>
      <v-form @submit.prevent="changePassword" ref="passwordChangeForm" v-if="passwordForm">
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :rules="[requiredRule]"
              counter
              dense
              label="වත්මන් මුරපදය"
              type="password"
              v-model="formData.currentPassword"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :rules="newPasswordRule"
              counter
              dense
              label="නව මුරපදය"
              type="password"
              v-model="formData.password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :rules="passwordConfirmationRule"
              counter
              dense
              label="නව මුරපදය නැවත ටයිප් කරන්න"
              type="password"
              v-model="formData.passwordConfirmation"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-btn
              :loading="loadingPwdChangeBtn"
              block
              color="accent"
              depressed
              type="submit"
            >සුරකින්න</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>

    <h6 class="mt-10 mb-5">සාමාජික මුරපද යළි පිහිටුවීම සඳහා</h6>
    <v-row>
      <v-col class="pt-0" cols="8" md="4">
        <v-text-field
          @keypress.enter="searchMember"
          class="mb-2 custom-shadow"
          clearable
          flat
          hide-details
          label="සාමාජික අංකය ඇතුලත් කරන්න"
          solo
          v-model.lazy="searchText"
        ></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="4" md="2">
        <v-btn
          :loading="loadingSearchBtn"
          @click="searchMember"
          class="custom-shadow"
          color="secondary"
          style="height: 48px;"
        >සොයන්න</v-btn>
      </v-col>
    </v-row>
    <v-sheet class="custom-shadow px-3 pt-2" v-if="member">
      <v-form @submit.prevent="resetMemberPassword" ref="memberPasswordForm">
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[requiredRule, lengthRule]"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              counter
              dense
              label="නව මුරපදය"
              v-model="memberPassword"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-1"></v-divider>
        <v-row>
          <v-col cols="12" lg="4" sm="6">
            <v-btn
              :loading="loadingMemberPwdBtn"
              block
              color="accent"
              depressed
              type="submit"
            >සුරකින්න</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { SHOW_ALERT } from "../../store/mutation-types";
import { ApiService } from '../../services/api.service';

export default {
  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    lengthRule: v => v.length >= 4 || 'අක්ෂර 4 ට වඩා වැඩි විය යුතුය',
    passwordForm: false,
    loadingPwdChangeBtn: false,
    loadingSearchBtn: false,
    loadingMemberPwdBtn: false,
    formData: {
      currentPassword: '',
      password: '',
      passwordConfirmation: ''
    },
    searchText: '',
    member: '',
    memberPassword: '',
    showPassword: false
  }),

  computed: {
    editButtonText: function () {
      if (this.passwordForm) {
        return "වසන්න";
      } else {
        return "සංස්කරණය කරන්න";
      }
    },
    newPasswordRule: function () {
      const { currentPassword, password } = this.formData;
      return [
        v => !!v || 'අවශ්‍යයි',
        v => v.length >= 4 || 'අක්ෂර 4 ට වඩා වැඩි විය යුතුය',
        () => !(currentPassword && password && (currentPassword === password)) || 'නව මුරපදය ඔබගේ වර්තමාන මුරපදයට සමාන විය නොහැක. කරුණාකර වෙනත් මුරපදයක් ඇතුලත් කරන්න.'
      ];
    },
    passwordConfirmationRule: function () {
      const { password, passwordConfirmation } = this.formData;
      return [
        v => !!v || 'අවශ්‍යයි',
        () => !(password && passwordConfirmation && (password !== passwordConfirmation)) || 'තහවුරු කිරීමේ මුරපදය නොගැලපේ.'
      ];
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    changePassword: async function () {
      if (this.$refs.passwordChangeForm.validate()) {
        this.loadingPwdChangeBtn = true;
        try {
          const url = 'admin/change-password';
          const response = await ApiService.post(url, this.formData);
          this.showAlert({ message: 'මුරපදය සාර්ථකව වෙනස් කරන ලදි', type: "success" });
        } catch (error) {
          if (error.response.data.message == 'Your current password does not matches with the password you provided. Please try again.') {
            this.showAlert({ message: 'ඔබගේ වර්තමාන මුරපදය ඔබ ලබා දුන් මුරපදය සමඟ නොගැලපේ. කරුණාකර නැවත උත්සාහ කරන්න.', type: "error" });
          } else {
            this.showAlert({ message: 'මුරපදය වෙනස් කිරීම අසාර්ථකයි.', type: "error" });
          }
        }
        this.loadingPwdChangeBtn = false;
      }
    },

    // Search member by id
    searchMember: async function () {
      this.loadingSearchBtn = true;
      try {
        const url = "customers";
        const params = {
          params: {
            id: this.searchText, approved: 1, nicVerified: 1, mobileNumberVerified: 1
          }
        };
        const response = await ApiService.get(url, params);
        this.member = response.data.data[0];
      } catch (error) {
        console.log(error);
      }
      this.loadingSearchBtn = false;
    },

    // Reset member password
    resetMemberPassword: async function () {
      if (this.$refs.memberPasswordForm.validate()) {
        this.loadingMemberPwdBtn = true;
        try {
          const url = 'customers/reset-password';
          const data = { id: this.member.id, password: this.memberPassword }
          const response = await ApiService.post(url, data);
          this.showAlert({ message: 'මුරපදය සාර්ථකව යළි පිහිටුවන ලදි', type: "success" });
        } catch (error) {
          this.showAlert({ message: 'මුරපදය යළි පිහිටුවීම අසාර්ථකයි.', type: "error" });
        }
        this.loadingMemberPwdBtn = false;
      }
    }
  }
}
</script>